<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-12">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Transferências de Estoque</h3>
        </div>
        <div class="card-body">
          <div class="row d-flex align-items-end">

            <div class="form-group col-md-3">
              <label class="col-md-12">Data Inicio:</label>
              <input required type="date" class="form-control" v-model="filtro.data_inicio" />
            </div>

            <div class="form-group col-md-3">
              <label class="col-md-12">Data Fim:</label>
              <input required type="date" class="form-control" v-model="filtro.data_fim" />
            </div>

            <div class="form-group col-md-2">
              <label>Status</label>
              <select required type="text" class="form-control" v-model="filtro.status">
                <option :value="null">Todos</option>
                <option value="0">Cancelado </option>
                <option value="1">Enviado </option>
                <option value="2">Recibido </option>
              </select>
            </div>

            <div class="form-group col-md-4">
              <label>Producto</label>
              <select required type="text" class="form-control" v-model="filtro.produto_id">
                <option value="">Selecione:</option>
                <option v-for="lista in lista_produtos" :key="lista.id" :value="lista.id">
                  {{ lista.nome }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-3">
              <label class="col-md-12"> Lote:</label>
              <input type="text" class="form-control" v-model="filtro.lote" />
            </div>

            <div class="form-group col-md-2 justify-content-end row">
              <div class="ml-1">
                <button class="btn btn-success" @click="listar_transferencias()">
                  <i class="fa fa-search search-icon m-0 p-0"></i>
                </button>
              </div>

              <div class="ml-1">

                <b-dropdown id="dropdown-1" variant="info" text="Imprimir">
                  <b-dropdown-item @click="gerarExcel()">Excel Transferencias</b-dropdown-item>
                  <b-dropdown-item @click="gerarPDF()">Nota de Remisión</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            
          </div>
          <b-table :fields="[
            'created',
            'produto',
            'descricao',
            'lote',
            'filial_antiga',
            'filial_atual',
            'status',
            'cant_enviada',
            'cant_recibida',
            'acoes',
          ]" :items="lista_transferencias" :per-page="perPage" :current-page="currentPage" id="produto-table"
            class="table table-head-custom table-vertical-center table-head-bg table-borderless pt-1 table-responsive"
            show-empty empty-text="Nenhum registro encontrado!">
            <template #head(acoes)><span></span></template>
            <template #cell(status)="{ item }">
              <span style="font-size: 12px" class="label label-lg label-inline" :class="{
                'label-light-warning': item.status === 1,
                'label-light-success': item.status === 2,
                'label-light-danger': item.status === 0,
              }">
                {{
                  item.status === 1
                    ? "Enviado"
                    : item.status === 2
                      ? "Recibido"
                      : item.status === 0
                        ? "Cancelado"
                        : ""
                }}
              </span>
            </template>
            <template #cell(filial_atual)="{ item }">
              <span v-if="item.filial_atual == null"> {{ item.futura_filial_atual }} </span>
              <span v-else="item.filial_atual == null"> {{ item.filial_atual }} </span>
            </template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button v-show="lista_permissoes_filial.u_Sala" @click="showDetalhes(item)"
                  class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Ver mas detalhes">
                  <i class="far fa-eye text-primary"></i>
                </button>
              </div>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="lista_produtos.length" :per-page="perPage"
            aria-controls="produto-table">
          </b-pagination>
          <b-modal centered ref="detalhes" size="lg" id="detalhes" title="Informaciones de la transferencia">
            <div class="my-4 form">

              <div class="row pt-1">
                <div class="col-md-6">
                  <label for="" class="col-md-12">Producto: </label>
                  <input disabled type="text" class="form-control" v-model="form.produto" />
                </div>
                <div class="col-md-6">
                  <label for="" class="col-md-12">Descripcion del producto: </label>
                  <input disabled type="text" class="form-control" v-model="form.desc_produto" />
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-md-3">
                  <label for="" class="col-md-12">Validad: </label>
                  <input disabled type="date" class="form-control" v-model="form.validade" />
                </div>
                <div class="col-md-3">
                  <label for="" class="col-md-12">Lote </label>
                  <input disabled type="text" class="form-control" v-model="form.lote" />
                </div>
                <div class="col-md-3">
                  <label for="" class="col-md-12">Cantidad Enviada: </label>
                  <input disabled type="text" class="form-control" v-model="form.quantidade" />
                </div>
                <div class="col-md-3">
                  <label for="" class="col-md-12">Cantidad Recibida: </label>
                  <input disabled type="text" class="form-control" v-model="form.quantidade_recebida" />
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-md-6">
                  <label for="" class="col-md-12">Filial Antigua</label>
                  <input disabled type="text" class="form-control" v-model="form.filial_antiga" />
                </div>

                <div class="col-md-6">
                  <label for="" class="col-md-12">Filial Destino</label>
                  <input v-if="form.filial_atual != null" disabled type="text" class="form-control"
                    v-model="form.filial_atual" />
                  <input v-else="form.filial_atual != null" disabled type="text" class="form-control"
                    v-model="form.futura_filial_atual" />
                </div>


              </div>
              <div class="row pt-3">
                <div class="col-md-12">
                  <label class="col-md-12">Descripcion</label>
                  <textarea disabled type="text" class="form-control" v-model="form.descricao"></textarea>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-md-6">
                  <label for="responsavel_envio" class="col-md-12 text-grey">Responsable envio: {{
                    form.responsavel_envio
                  }}</label>
                  <label for="responsavel_recibo" class="col-md-12 text-grey">Responsable Recibo: {{
                    form.responsavel_recibo
                  }}</label>
                </div>
                <div class="col-md-6">
                  <labeL class="col-md-12 text-grey">Fecha envio: {{ formatData(form.created) }}</label>
                  <label v-if="form.status != 0" class="col-md-12 text-grey">Fecha Recibo: {{
                    formatData(form.updated_at)
                  }}</label>
                  <label v-if="form.status == 0" class="col-md-12 text-grey">Fecha Confirmación: {{
                    formatData(form.updated_at)
                  }}</label>
                </div>
              </div>
            </div>
            <template #modal-footer>
              <div class="text-center w-100">

                <button v-if="form.status == 1" v-show="lista_permissoes_filial.u_Sala"
                  @click="confirm(form.id, 'cancelar')" class="btn btn-danger">
                  Cancelar
                </button>
                <button v-if="form.status != 1" v-show="lista_permissoes_filial.u_Sala" disabled class="btn btn-danger">
                  Cancelar
                </button>

                <button v-if="form.status == 1" v-show="lista_permissoes_filial.u_Sala" @click="showRecibir()"
                  class="btn btn-success m-1">
                  Recibir
                </button>
                <button v-if="form.status != 1" v-show="lista_permissoes_filial.u_Sala" disabled
                  class="btn btn-success m-1">
                  Recibir
                </button>

                <button @click="fechar('detalhes')" class="btn btn-light ml-1">
                  Cerrar
                </button>

              </div>
            </template>
          </b-modal>
        </div>
        <div class="card-footer text-center text-grey">
          <h5>{{ $t("TRANSFERENCIA.TOTAL") }}: {{ soma_quantidade }}</h5>
        </div>
      </div>
    </div>
    <b-modal centered ref="recibir" id="recibir" title="Recibir Transfência">
      <div class="my-4 form">
        <div class="row pt-3">

          <div class="col-md-6">
            <label for="" class="col-md-12">Cantidad: </label>
            <input type="number" class="form-control" v-model="form.quantidade_recebida" />
          </div>
          <div class="col-md-6">
            <label for="" class="col-md-12">Fracion: </label>
            <input type="number" disabled class="form-control" v-model="estoque_fracao" />
          </div>

        </div>

        <div class="row pt-3">
          <div class="col-md-12">
            <label class="col-md-12">Descripcion</label>
            <textarea type="text" class="form-control" v-model="form.descricao"></textarea>
          </div>



        </div>

      </div>
      <template #modal-footer>
        <div class="text-center w-100">



          <button v-if="form.status == 1" v-show="lista_permissoes_filial.u_Sala" @click="confirm(form.id, 'recibir')"
            class="btn btn-success m-1">
            <i class="fa fa-check m-0 p-0"></i>
            Recibir
          </button>


          <button @click="fechar('recibir')" class="btn btn-light ml-1">
            Cerrar
          </button>

        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
export default {
  mixins: { fireAlert },
  data() {
    return {
      filtro: {
        produto_id: null,
        lote: null,
        data_inicio: null,
        data_fim: null,
        status: null,
        tipo_transferencia: 1,
        responsavel_id: null
      },

      estoque_fracao: null,
      form: [],
      soma_quantidade: 0,
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Relatorio" }]);
  },
  created() {
    this.listar_produtos();
  },
  computed: {
    lista_produtos() {
      return this.$store.state.produto.lista_produtos;
    },
    lista_transferencias() {
      return this.$store.state.produto.lista_transferencias;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
  },
  watch: {
    'form.quantidade_recebida'(value) {
      this.estoque_fracao = value * this.form.fracao
    },
  },
  methods: {
    formatData(value) {
      if (value != null) {
        var data = new Date(value)
        return data.toLocaleString()
      }
    },
    async listar_transferencias() {
      this.soma_quantidade = 0;
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("produto/listar_transferencias", this.filtro)
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
        });

      for (var items in this.lista_transferencias) {
        this.soma_quantidade =
          this.soma_quantidade + this.lista_transferencias[items].quantidade;
      }
    },

    async listar_produtos() {
      await this.$store.dispatch("produto/listar_produtos");
    },

    atualizar(value) {
      this.$router.push({
        name: "produtoDados",
        params: { produto_id: value.id },
      });
      // this.$store.dispatch("configEmpresa/atualizar", value);
      // localStorage.setItem('produto',JSON.stringify(value));
      // this.$store.dispatch("produto/listar_produto", value.id);
    },
    async confirm(id, acao) {
      var msg = `Esta acción eliminará la transferencia. La cantidad volverá a la sucursal original`
      if (acao == 'recibir') msg = `La cantidad confirmada será adicionada al estoque de la nueva filial`
      await this.$confirm({
        title: "Estas seguro?",
        message: msg,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (acao == 'recibir') this.receber_transferencia()
            else
              this.desfazer_transferencia(id);
          }
        },
      });
    },
    async desfazer_transferencia(id) {
      console.log(id);
      await this.$store.dispatch("produto/desfazer_transferencia", id);


      await this.listar_transferencias()
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
      if (this.mensagem_alert.tipo == 'success') {

        this.$refs["detalhes"].hide();

      }
    },
    async receber_transferencia() {
      var dados = {
        id: this.form.id,
        lote: this.form.lote,
        produto_id: this.form.produto_id,
        fracao: this.form.fracao,
        estoque_fracao: this.estoque_fracao,
        estoque_quantidade: this.form.quantidade_recebida,
        validade: this.form.validade,
        valor_unitario: this.form.valor_unitario,
        valor_fracionario: this.form.valor_fracionario,
        filial_id: this.form.futura_filial_id,
        quantidade_recebida: this.form.quantidade_recebida,
        descricao: this.form.descricao

      }
      await this.$store.dispatch("produto/receber_estoque", dados);
      console.log(this.mensagem_alert.message);
      this.listar_transferencias()
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
      if (this.mensagem_alert.tipo == 'success') {
        this.$refs["recibir"].hide();
        this.$refs["detalhes"].hide();
      }
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
    async gerarExcel() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      console.log(this.filtro);
      await this.$store.dispatch("produto/excel_transferencias", this.filtro);
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
    },
    async gerarPDF() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      try {
        await this.$store.dispatch("produto/pdf_transferencias", this.filtro);
   
      } catch (error) {
        alert("Erro ao gerar pdf")
      } 
      this.$store.dispatch("configEmpresa/MudarPreloader", false);

    },

    showDetalhes(item) {
      this.$refs["detalhes"].show();
      this.form = item;
      console.log(this.form);
    },
    showRecibir() {
      if (this.form.quantidade_recebida == null) { this.form.quantidade_recebida = this.form.quantidade }
      this.estoque_fracao = this.form.quantidade_recebida * this.form.fracao
      this.$refs["recibir"].show();
      console.log(this.form);
    },
    fechar(value) {
      this.$refs[value].hide();
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>